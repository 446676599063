import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator, SAMLAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

const setSamlProvider = (providerId) => {
  if (providerId) {
    return new SAMLAuthProvider(providerId);
  } else {
    return null;
  }
};
export const samlProvider = setSamlProvider(process.env.REACT_APP_FIREBASE_AUTH_SAML_PROVIDER_ID);

if (process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_URL) {
  connectAuthEmulator(auth, process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_URL);
}
